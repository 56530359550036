var zh = require("../../../../libs/zh.json");
export default {
  name: "bubble",
  components: {},
  props: {},
  data() {
    return {
      series: [{
        name: "直营",
        data: this.generateData(10, {
          min: 10,
          max: 60
        })
      }, {
        name: "加盟商",
        data: this.generateData(10, {
          min: 10,
          max: 60
        })
      }, {
        name: "赞助商",
        data: this.generateData(10, {
          min: 10,
          max: 60
        })
      }, {
        name: "广告商",
        data: this.generateData(15, {
          min: 10,
          max: 60
        })
      }],
      chartOptions: {
        chart: {
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          locales: [zh],
          defaultLocale: "zh"
        },
        fill: {
          opacity: 0.8
        },
        legend: {
          show: true,
          offsetY: 5
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          tickAmount: 12,
          type: "category"
        }
      }
    };
  },
  methods: {
    generateData(count, yrange) {
      let i = 0;
      let series = [];
      while (i < count) {
        let x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
        let y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        let z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;
        series.push([x, y, z]);
        i++;
      }
      return series;
    },
    init() {
      this.$nextTick(() => {
        this.series = [{
          name: "直营",
          data: this.generateData(10, {
            min: 10,
            max: 60
          })
        }, {
          name: "加盟商",
          data: this.generateData(10, {
            min: 10,
            max: 60
          })
        }, {
          name: "赞助商",
          data: this.generateData(10, {
            min: 10,
            max: 60
          })
        }, {
          name: "广告商",
          data: this.generateData(15, {
            min: 10,
            max: 60
          })
        }];
      });
    }
  },
  mounted() {
    this.init();
  }
};