var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-bubble"
  }, [_c('Card', [_c('p', {
    staticClass: "card-title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("订单分析")]), _c('div', {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_c('DatePicker', {
    staticStyle: {
      "width": "220px"
    },
    attrs: {
      "type": "year",
      "placeholder": "选择查看的年份"
    }
  })], 1), _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "chart"
  }, [_c('apexchart', {
    attrs: {
      "type": "bubble",
      "height": "380",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1), _c('Row', {
    staticClass: "row"
  }, [_c('Col', {
    staticClass: "text",
    attrs: {
      "span": "8"
    }
  }, [_c('h6', {}, [_vm._v("距离目标")]), _c('h3', [_c('Icon', {
    attrs: {
      "type": "ios-arrow-round-up",
      "size": "28",
      "color": "#23b397"
    }
  }), _vm._v("645 ")], 1)]), _c('Col', {
    staticClass: "text",
    attrs: {
      "span": "8"
    }
  }, [_c('h6', {}, [_vm._v("同比上周")]), _c('h3', [_c('Icon', {
    attrs: {
      "type": "ios-arrow-round-down",
      "size": "28",
      "color": "#f0643b"
    }
  }), _vm._v("289 ")], 1)]), _c('Col', {
    staticClass: "text",
    attrs: {
      "span": "8"
    }
  }, [_c('h6', {}, [_vm._v("同比上月")]), _c('h3', [_c('Icon', {
    attrs: {
      "type": "ios-arrow-round-up",
      "size": "28",
      "color": "#23b397"
    }
  }), _vm._v("3,476 ")], 1)])], 1)], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };